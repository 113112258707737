import React from "react"
import { Box } from '@mui/material';
import Experiment from '../../components/experiments/experiment';

export default () => {
    return (
        <Box mx={2}>
            <Experiment uuid="074f3d94-8f87-41ca-9190-3abf81bf9468" />
        </Box>
    );
};